import React from 'react'

import { useSession } from '@/contexts/auth/SessionProvider'
import { getJwtToken, parseJwt } from '@/utils/auth'

interface Impersonator {
  id: string
  role: 'BACKOFFICE'
  redirectTo?: string
}

export function useIsImpersonating() {
  const { status } = useSession()

  return React.useMemo(() => {
    if (status !== 'authenticated') {
      return { isImpersonating: false }
    }

    const jwt = getJwtToken()
    if (!jwt) return { isImpersonating: false }

    const { payload } = parseJwt(jwt)

    return {
      isImpersonating: Boolean(payload?.impersonator),
      impersonator: payload?.impersonator as Impersonator | undefined,
    }
  }, [status])
}
