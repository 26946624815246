/**
 * This is just a temporary workaround to make next-intl work with pages and app directores
 * simultaneously without having to defined getServerSideProps (or static) manually for each page
 * or to create a webpack loader to add it to every page accordingly.
 * Trade off: page size will include all JSON inside message.
 */

import type { Locale } from '@/i18n'

import en from './en.json'
import ptBR from './pt-br.json'

const localeMap = { en, 'pt-br': ptBR } satisfies Record<Locale, unknown>

export default localeMap
