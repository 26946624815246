import Script from 'next/script'
import React from 'react'

const GlobalScripts: React.FC = () => {
  return (
    <>
      {/* <Script
        id="neurolake"
        src="https://neurolake-digital-neurotracker.s3.amazonaws.com/ozenergia.js"
      /> */}

      <Script
        id="google"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W27B3PW9');`,
        }}
      />

      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W27B3PW9"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />

      {/* <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-11328778639"
      />
      <Script
        id="google_tags"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());          
          gtag('config', 'AW-11328778639');
              `,
        }}
      />
      <Script
        id="google_tags"
        dangerouslySetInnerHTML={{
          __html: `
          gtag('event', 'conversion', {'send_to': 'AW-11328778639/Er-kCNWanN8YEI_j_Zkq'});
          `,
        }}
      /> */}
    </>
  )
}
export default GlobalScripts
