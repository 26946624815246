import '../../styles/globals.css'

import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { DefaultSeo } from 'next-seo'
import { posthog } from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { ConfigProvider } from '@/contexts/core/Config.context'
import type { Page } from '@/utils/types/etc'

const DynamicGlobals = dynamic(() => import('@/components/core/DynamicGlobals'))

import { NextIntlClientProvider } from 'next-intl'

import { InitializeGoogleAnalytics } from '@/components/Analytics'
import { MarketingFormProvider } from '@/components/backoffice/Marketing/CampaignContext'
import ClientError from '@/components/core/ClientError'
import GlobalScripts from '@/components/core/GlobalScripts'
import { SessionProvider } from '@/contexts/auth/SessionProvider'
import type { Locale } from '@/i18n'
import { env } from '@/utils/envs'

import messagesModule from '../../messages'
import seo from '../../next-seo.config'

type AppWithLayout = AppProps & {
  Component: Page<AppProps['pageProps']>
  err: any
}

if (typeof window !== 'undefined') {
  // checks that we are client-side
  const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY
  const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST

  if (!posthogKey || !posthogHost) {
    throw new Error('Missing posthog environemnt variables')
  }

  posthog.init(posthogKey, {
    api_host: posthogHost,
    disable_session_recording: process.env.NODE_ENV === 'development',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug() // debug mode in development
    },
  })
}

const MyApp = ({
  Component,
  pageProps: { session, dehydratedState, ...pageProps } = {},
  err,
}: AppWithLayout) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: { queries: { staleTime: 1000 * 60 } },
      })
  )

  const getLayout = React.useMemo(
    () => Component.getLayout ?? ((page: React.ReactElement) => page),
    [Component.getLayout]
  )

  const locale = env.LANGUAGE as Locale
  const messages = React.useMemo(() => messagesModule[locale], [locale])

  React.useEffect(() => {
    InitializeGoogleAnalytics()
  }, [])

  return (
    <>
      <DefaultSeo {...seo} />
      <GlobalScripts />
      <NextIntlClientProvider
        locale={locale}
        messages={messages}
        timeZone="America/Sao_Paulo"
      >
        <ErrorBoundary FallbackComponent={ClientError}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={dehydratedState}>
              <SessionProvider session={session}>
                <MarketingFormProvider>
                  <DynamicGlobals />
                  <ConfigProvider>
                    <PostHogProvider client={posthog}>
                      {getLayout(<Component {...pageProps} err={err} />)}
                    </PostHogProvider>
                  </ConfigProvider>
                </MarketingFormProvider>
              </SessionProvider>
              <ReactQueryDevtools />
            </Hydrate>
          </QueryClientProvider>
        </ErrorBoundary>
      </NextIntlClientProvider>
    </>
  )
}

export default MyApp
