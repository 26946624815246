import type { GetServerSidePropsContext } from 'next'
import type { NextRequest, NextResponse } from 'next/server'

import * as fetch from '@/utils/fetch'

import { ACCESS_TOKEN_COOKIE } from './constants'
import type { MeResponseDto } from './types/api/portal/dto'

type AnyRequest = GetServerSidePropsContext['req'] | NextRequest

function getCookieFromAnyRequest(req: AnyRequest, name: string) {
  if (typeof req.cookies.get === 'function') {
    return req.cookies.get(name)?.value
  }

  if (typeof (req.cookies as any)?.[name] === 'string') {
    return (req.cookies as any)[name]
  }
}

export function parseCookies(cookiesStr: string) {
  return cookiesStr.split('; ').reduce((acm, crr) => {
    const [name, value] = crr.split('=')
    return {
      ...acm,
      [name]: value,
    }
  }, {} as Record<string, string>)
}

export function getJwtToken(req?: AnyRequest): string | undefined {
  if (typeof document !== 'undefined') {
    return parseCookies(document.cookie)[ACCESS_TOKEN_COOKIE]
  } else if (req) {
    return getCookieFromAnyRequest(req, ACCESS_TOKEN_COOKIE)
  } else {
    return undefined
  }
}

export function parseJwt(jwt: string): {
  header?: Record<string, any>
  payload?: Record<string, any>
  signature?: Record<string, any>
} {
  const [header, payload, signature] = jwt.split('.').map((base64) => {
    try {
      return JSON.parse(Buffer.from(base64, 'base64').toString())
    } catch {
      return {}
    }
  })
  return { header, payload, signature }
}

export function setJwtToken({
  jwt,
  // One week in seconds
  maxAge = 60 * 60 * 24 * 7,
  res,
}: {
  jwt?: string | null
  res?: NextResponse
  maxAge?: number
}) {
  const cookie = `${ACCESS_TOKEN_COOKIE}=${jwt};path=/;max-age=${
    jwt ? maxAge : 0
  };secure=true;sameSite=strict`

  if (typeof document !== 'undefined') {
    document.cookie = cookie
  } else if (res) {
    res.headers.set('Set-Cookie', cookie)
  }
}

export function parseSessionFromAuthMe(data: MeResponseDto) {
  let profile

  const {
    integrador,
    distribuidor,
    cliente,
    backoffice,
    funding,
    vendedor,
    ...credential
  } = data

  switch (credential?.roles[0]) {
    case 'INTEGRADOR': {
      profile = integrador
      break
    }
    case 'DISTRIBUIDOR': {
      profile = distribuidor
      break
    }
    case 'CLIENTE': {
      profile = cliente
      break
    }
    case 'BACKOFFICE': {
      profile = backoffice
      break
    }
    case 'FINANCIADOR': {
      profile = funding
      break
    }
    case 'VENDEDOR': {
      profile = vendedor
      break
    }
  }

  return {
    profile,
    user: {
      role: credential.roles?.[0],
      department: credential.department || null,
    },
    credential,
  }
}

export async function getServerSession(req: AnyRequest) {
  const meResponse = await fetch
    .portal<MeResponseDto>('/auth/me', { req })
    .then(({ data }) => data)

  if (meResponse) {
    return parseSessionFromAuthMe(meResponse)
  } else {
    return null
  }
}
