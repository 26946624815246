import Link from 'next/link'
import { useTranslations } from 'next-intl'
import * as React from 'react'

import { c } from '@/utils/etc'

export interface SimulacaoCTAProps {
  className?: string
  internClassName?: string
}

export const SimulacaoCTA: React.FCC<SimulacaoCTAProps> = ({
  className,
  internClassName,
}) => {
  const t = useTranslations('common')
  return (
    <Link href="/simulacao" passHref legacyBehavior>
      <Button
        innerClassName={c('flex items-center', internClassName)}
        className={c('button--primary max-w-max py-2 pr-2', className)}
      >
        <p className="flex-1 text-center font-semibold text-white">
          {t('SIMULATION_BUTTON')}
        </p>

        <div className="ml-2 flex h-9 w-9 items-center justify-center rounded-full bg-white">
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="h-6 w-6 text-warning-300"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </div>
      </Button>
    </Link>
  )
}

// --------------------------------------------------------------------------------------------

export const Loader: React.FCC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={c('text-[inherit] absolute h-6 w-6 animate-spin', className)}
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
      />
    </svg>
  )
}

// --------------------------------------------------------------------------------------------

const RenderLink: React.FCC<any> = React.forwardRef(
  ({ href, target, ...props }, ref) => {
    return <Link href={href} ref={ref} target={target} {...props}></Link>
  }
)

// --------------------------------------------------------------------------------------------

export interface ButtonProps {
  type?: 'button' | 'submit' | 'reset'
  href?: string | null
  loading?: boolean
  disabled?: boolean
  className?: string
  innerClassName?: string
  target?: string
  testID?: string
  onClick?: (evt: React.MouseEvent) => void
}

const Button = React.forwardRef<any, React.PropsWithChildren<ButtonProps>>(
  (
    {
      type = 'button',
      href,
      children,
      className,
      disabled,
      loading: _loading,
      innerClassName,
      onClick,
      target,
      testID,
    },
    ref
  ) => {
    const [fetching, setFetching] = React.useState(false)
    const loading = React.useMemo(
      () => _loading || fetching,
      [_loading, fetching]
    )

    const handleClick = React.useCallback<React.MouseEventHandler>(
      async (evt) => {
        if (!onClick) return
        setFetching(true)
        try {
          await onClick(evt)
        } finally {
          setFetching(false)
        }
      },
      [onClick]
    )

    return React.createElement(
      href && !disabled ? RenderLink : 'button',
      {
        'data-test-id': testID,
        className: c(
          'button flex items-center justify-center',
          className,
          !className?.includes('absolute') && 'relative'
        ),
        ...(href && !disabled
          ? { ref, href, disabled, onClick: handleClick, target }
          : { type, disabled, onClick: handleClick }),
      },
      <>
        <Loader
          className={c(
            'absolute inset-x-0 mx-auto w-4 [color:inherit]',
            loading ? 'visible' : 'invisible'
          )}
        />
        <span className={c(loading ? 'invisible' : 'visible', innerClassName)}>
          {children}
        </span>
      </>
    )
  }
)

export default Button
