import { useQuery } from '@tanstack/react-query'
import * as React from 'react'

import { useAuth } from '@/hooks/use-auth'
import * as fetch from '@/utils/fetch'

interface ConfigState {
  validadeDiasNeurotechP1: number
  parcelamento: {
    taxa: number
  }
  milestonesSplit: {
    m1: number
    m2: number
    m3: number
  }
}

const ConfigContext = React.createContext<ConfigState>({} as never)

export const useConfig = () => React.useContext(ConfigContext)

export const ConfigProvider: React.FCC = ({ children }) => {
  const { user } = useAuth()
  const { data } = useQuery<ConfigState>(
    ['preferencias'],
    () =>
      fetch.portal<any>('/backoffice/buscar/preferencias').then(({ data }) => {
        return {
          parcelamento: { taxa: data.taxaPropostaGlobal },
          validadeDiasNeurotechP1: data.diasPrazoValidadeConsultaNeurotech,
          milestonesSplit: data.milestonesSplit,
        }
      }),
    {
      enabled: Boolean(user),
      refetchOnWindowFocus: false,
    }
  )

  const value = React.useMemo<ConfigState>(
    // Should not have this default value. These configuratios should be called when need not globally
    () => (data || { parcelamento: { taxa: 0.0195 } }) as ConfigState,
    [data]
  )

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  )
}
