import ReactGA4 from 'react-ga4'
const InitializeGoogleAnalytics = () => {
  ReactGA4.initialize(`${process.env.NEXT_PUBLIC_ANALYTICS_TOKEN}`)
}

const TrackGoogleAnalyticsEvent = (
  category: string,
  action: string,
  label: string
) => {
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
  })
}

export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent }
