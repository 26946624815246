import type { ReactNode } from 'react'
import React, { createContext, useContext, useState } from 'react'

interface MarketingFormContextProps {
  isModalActive: boolean
  openModal: () => void
  closeModal: () => void
  openEditModal: () => void
  closeEditModal: () => void
  isEditModalActive: boolean
  getCampaignId: (id: string) => void
  campaignId: string
  clearCampaignId: () => void
}

const MarketingFormContext = createContext<
  MarketingFormContextProps | undefined
>(undefined)

export const MarketingFormProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isModalActive, setIsModalActive] = useState(false)
  const [isEditModalActive, setIsEditModalActive] = useState(false)
  const [campaignId, setCampaignId] = useState('')

  const openModal = () => setIsModalActive(true)
  const closeModal = () => setIsModalActive(false)

  const openEditModal = () => setIsEditModalActive(true)
  const closeEditModal = () => setIsEditModalActive(false)

  const getCampaignId = (id: string) => setCampaignId(id)
  const clearCampaignId = () => setCampaignId('')

  return (
    <MarketingFormContext.Provider
      value={{
        isModalActive,
        openModal,
        closeModal,
        closeEditModal,
        openEditModal,
        isEditModalActive,
        getCampaignId,
        campaignId,
        clearCampaignId,
      }}
    >
      {children}
    </MarketingFormContext.Provider>
  )
}

export const useMarketingFormContext = () => {
  const context = useContext(MarketingFormContext)
  if (!context) {
    throw new Error(
      'useMarketingFormContext must be used in a MarketingFormProvider'
    )
  }
  return context
}
