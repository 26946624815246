/**
 * ==================================================================================================
 * Travishorn Finance
 * Credits: https://github.com/travishorn/finance/blob/master/src/irr.ts
 * ==================================================================================================
 */

/**
 * Private helper function for the `irr` function.
 */
const travishorn_internalPv = (values: number[], guess: number): number => {
  guess = typeof guess === 'undefined' ? 0.1 : guess

  let lowerBound = 0
  const upperBound = values.length - 1

  let tmpTotal = 0
  const divRate = 1 + guess

  while (lowerBound <= upperBound && values[lowerBound] === 0) {
    lowerBound++
  }

  let i = upperBound
  const step = -1

  while (i >= lowerBound) {
    tmpTotal = tmpTotal / divRate
    tmpTotal = tmpTotal + values[i]
    i = i + step
  }
  return tmpTotal
}

/**
 * Calculate the internal rate of return of a series of regular cash flows.
 *
 * @remarks
 * Negative values represent investments, and positive values represent returns.
 *
 * Essentially, the algorithm uses the secant method to find a rate where the
 * net present value is equal to 0, stepping through the calculations
 * iteratively. Once the rate is within the Epsilon tolerance, the approximate
 * rate is returned.
 *
 * @param values - A set of periodic cash flows
 * @param guess - A guess at the rate
 * @returns The internal rate of return
 */
export const travishorn_irr = (values: number[], guess = 0.1): number => {
  const epslMax = 0.0000001
  const step = 0.00001
  const iterMax = 1000

  //Check for valid inputs
  if (guess <= -1) throw new Error('Invalid guess')
  if (values.length < 1) throw new Error('Invalid input')

  //Scale up the Epsilon Max based on cash flow values
  let tmp = values[0] > 0 ? values[0] : values[0] * -1

  values.forEach((value) => {
    if (Math.abs(value) > tmp) tmp = Math.abs(value)
  })

  const tmpNpvEpsl = tmp * epslMax * 0.01

  let tmpRate0 = guess
  let tmpNpv0 = travishorn_internalPv(values, tmpRate0)

  let tmpRate1 = tmpNpv0 > 0 ? tmpRate0 + step : tmpRate0 - step

  if (tmpRate1 <= -1) throw new Error('Invalid values')

  let tmpNpv1 = travishorn_internalPv(values, tmpRate1)

  let i = 0

  while (i <= iterMax) {
    if (tmpNpv1 === tmpNpv0) {
      tmpRate0 = tmpRate1 > tmpRate0 ? tmpRate0 - step : tmpRate0 + step

      tmpNpv0 = travishorn_internalPv(values, tmpRate0)

      if (tmpNpv1 === tmpNpv0) {
        throw new Error('Invalid values')
      }
    }

    tmpRate0 =
      tmpRate1 - ((tmpRate1 - tmpRate0) * tmpNpv1) / (tmpNpv1 - tmpNpv0)

    //Secant method
    if (tmpRate0 <= -1) tmpRate0 = (tmpRate1 - 1) * 0.5

    //Give the algorithm a second chance...
    tmpNpv0 = travishorn_internalPv(values, tmpRate0)
    tmp = tmpRate0 > tmpRate1 ? tmpRate0 - tmpRate1 : tmpRate1 - tmpRate0

    const tmp2 = tmpNpv0 > 0 ? tmpNpv0 : tmpNpv0 * -1

    //Test for npv = 0 and rate convergence
    if (tmp2 < tmpNpvEpsl && tmp < epslMax) {
      return tmpRate0
    }

    //Transfer values and try again...
    tmp = tmpNpv0
    tmpNpv0 = tmpNpv1
    tmpNpv1 = tmp
    tmp = tmpRate0
    tmpRate0 = tmpRate1
    tmpRate1 = tmp

    i++
  }

  throw new Error('Maximum iterations exceeded')
}

/**
 * ==================================================================================================
 * Travishorn Finance
 * ==================================================================================================
 */

// --------------------------------------------------------------------------------------------------

/**
 * ==================================================================================================
 * Stackoverflow
 * Credits: https://pt.stackoverflow.com/questions/96825/como-calcular-o-vpl-npv-e-o-tir-irr-usando-javascript
 * ==================================================================================================
 */

function so_vpl(taxa: number, montantes: number[]) {
  let ret = montantes[0]

  for (let i = 1; i < montantes.length; i++)
    ret += montantes[i] / Math.pow(1.0 + taxa, i)
  return ret
}

function so_sinal(x: number) {
  return x < 0.0 ? -1 : 1
}

export const so_irr = (montantes: number[]) => {
  // let ret = -1000000000.0
  let juros_inicial = -1.0
  // let juros_medio = 0.0
  let juros_final = 1.0
  let vpl_inicial = 0.0
  let vpl_final = 0.0
  // let vf = 0.0
  const erro = 1e-5 // Valor mínimo que determina que a raiz foi encontrada

  // Procura um possível intervalo para a raiz
  // O looping deve terminar antes de i chegar a 100!

  for (let i = 0; i < 100; i++) {
    vpl_inicial = so_vpl(juros_inicial, montantes)
    vpl_final = so_vpl(juros_final, montantes)
    if (so_sinal(vpl_inicial) != so_sinal(vpl_final)) break
    juros_inicial -= 1.0
    juros_final += 1.0
  }

  // Contador que evita um looping infinito
  let count = 0

  // Busca por Bisseção
  for (;;) {
    const juros_medio = (juros_inicial + juros_final) / 2.0
    const vpl_medio = so_vpl(juros_medio, montantes)

    if (Math.abs(vpl_medio) <= erro) {
      // Resultado foi encontrado
      return juros_medio * 100.0
    }

    if (so_sinal(vpl_inicial) == so_sinal(vpl_medio)) {
      juros_inicial = juros_medio
      vpl_inicial = so_vpl(juros_medio, montantes)
    } else {
      juros_final = juros_medio
      vpl_final = so_vpl(juros_medio, montantes)
    }

    // Evita um possível looping infinito
    if (++count > 10000) throw 'looping inválido'
  }
}

/**
 * ==================================================================================================
 * Stackoverflow
 * ==================================================================================================
 */
