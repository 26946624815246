import { ACCESS_TOKEN_COOKIE } from '@/utils/constants'

import { getJwtToken } from './auth'
import { env } from './envs'
import type { ServerResponse } from './types'

export const portalEndpoint = env.PORTAL_BACKEND
export const storageEndpoint = env.STORAGE_BACKEND
export const simulacaoMsEndpoint = env.SIMULACAO_MS
export const formalizacaoEndpoint = env.FORMALIZACAO_URL

const makeApiFetcher = (prefix: string) => {
  const fetcher = async <T>(
    url: string,
    init?: RequestInit & {
      noparse?: boolean
      req?: any
    }
  ) => {
    if (!url.startsWith('/'))
      throw "Auto prefixed fetch URL must be started with '/'"

    let token = getJwtToken(init?.req)
    // for Server Components
    if (typeof window === 'undefined') {
      token ||= await import('next/headers')
        .then(({ cookies }) => cookies().get(ACCESS_TOKEN_COOKIE)?.value)
        .catch(() => undefined)
    }
    const options: RequestInit = {}

    if (token) {
      options.headers = { ...options.headers, Authorization: `Bearer ${token}` }
    }

    return fetch(`${prefix}${url}`, {
      ...options,
      ...init,
      headers: { ...options.headers, ...init?.headers },
    })
      .then((r) => {
        // This is used to logout if token has expired
        // if (r.status === 401 && typeof window !== 'undefined') {
        //   const cookies = parseCookies(document.cookie)

        //   if (
        //     !cookies[UNAUTH_REDIRECT_COOKIE] &&
        //     location.pathname !== '/login'
        //   ) {
        //     document.cookie = `${UNAUTH_REDIRECT_COOKIE}=true`
        //     setJwtToken({ jwt: null })
        //     location.assign(
        //       `/login?callbackUrl=${encodeURIComponent(location.pathname)}`
        //     )
        //   }
        // }
        return r
      })
      .then(async (r) => {
        if (init?.noparse) {
          if (r.ok) return r
          else throw r
        }

        const parsed = r.headers
          .get('Content-Type')
          ?.startsWith('application/json')
          ? await r.json()
          : await r.text()

        if (!r.ok) {
          throw parsed
        } else {
          return parsed
        }
      })
      .then((j) => j as ServerResponse<T>)
  }

  return fetcher
}

// export const portal = makeApiFetcher<PortalRoutes>(portalEndpoint)
export const portal = makeApiFetcher(portalEndpoint)
export const storage = makeApiFetcher(storageEndpoint)
export const formalizacao = makeApiFetcher(formalizacaoEndpoint)

// export const $fetch = { portal, storage }
